@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Shippori+Mincho:wght@500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Outfit:wght@100..900&family=Shippori+Mincho:wght@700&display=swap');

* {
    box-sizing: border-box;
    
}